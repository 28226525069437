import { useEffect, useState, type FC, type ReactElement } from "react";
import { Button, Collapse, Divider, Flex, Tabs } from "antd";
import {
  getCircleColor,
  getCircleColorNutrient,
} from "../../../../shared/layout/Plots/utils";
import "../../style.less";
import type { NewTrialType, ProtocolResponse, Replicants } from "../../types";
import TabPane from "antd/es/tabs/TabPane";
import ProtcolDrawer from "../../../../shared/layout/ProtocolDrawer";
import { InfoCircleOutlined } from "@ant-design/icons";
import { useNewTrialState } from "../../hooks/NewTrialStateProvider";

const RepicantsLabel = ({
  data = [],
}: {
  data: Replicants[];
}): ReactElement => <>{`+ ${data.length} replicants`}</>;

interface SummaryRatesAndDosageProps {
  data: NewTrialType;
  trialProtocolData: ProtocolResponse | null;
}

const RatesAndDosagesGrid: FC<{
  data: NewTrialType;
  trialProtocolData: ProtocolResponse | null;
  type: "seeds" | "fertilisers";
}> = ({ data, trialProtocolData, type }) => {
  return (
    <Flex vertical={true} gap="10px">
      {data.trial_plots?.map((plot, plotIndex: number) => (
        <div className="collapse-one" key={plotIndex}>
          <Collapse expandIconPosition="start">
            <Collapse.Panel
              style={{ padding: "4px 8px" }}
              header={
                <Flex className="plots-col" align="center">
                  <h4>Plot {plotIndex + 1}</h4>
                  {plot?.replicants?.length ? (
                    <RepicantsLabel data={plot.replicants || []} />
                  ) : null}
                </Flex>
              }
              key={plotIndex}
            >
              {plot?.properties?.[type]?.rates_and_dosages?.map(
                (rateAndDosage, rateIndex: number) => (
                  <div key={rateIndex} className="rates-item">
                    <Flex
                      className="rates-item"
                      vertical={false}
                      gap={10}
                      align="center"
                      style={{ padding: "8px 4px" }}
                    >
                      <Flex className="circle-container">
                        <div
                          className="color-circle"
                          style={{
                            backgroundColor:
                              type === "seeds"
                                ? getCircleColor(
                                    "variety" in rateAndDosage
                                      ? rateAndDosage.variety
                                      : "",
                                    rateAndDosage.rate,
                                    trialProtocolData?.product_details?.seeds
                                      ?.products,
                                    null,
                                    plot?.properties?.seeds?.rates_and_dosages
                                  )
                                : getCircleColorNutrient(
                                    plot?.properties?.fertilisers
                                      ?.rates_and_dosages,
                                    rateAndDosage.rate,
                                    "rate",
                                    plotIndex
                                  ),
                          }}
                        ></div>
                      </Flex>
                      <Flex className="summary-rate-plot-text">
                        Plot-<span>{rateIndex + 1}</span>
                      </Flex>

                      <Flex
                        className="summary-rate-text"
                        style={{
                          borderLeft: "1px solid #d9d9d9",
                          paddingLeft: "8px",
                        }}
                      >
                        {"variety" in rateAndDosage
                          ? `${rateAndDosage.variety} (${rateAndDosage.rate} ${plot.properties.seeds.measure_unit})`
                          : `Amount: ${rateAndDosage.amount} (kg/ha)`}
                      </Flex>
                    </Flex>
                    <Divider className="text-md" style={{ margin: "8px" }} />
                  </div>
                )
              )}
            </Collapse.Panel>
          </Collapse>
        </div>
      ))}
    </Flex>
  );
};

const SummaryRatesAndDosage: FC<SummaryRatesAndDosageProps> = ({
  data,
  trialProtocolData,
}) => {
  const { setIsFertilizerTabActive } = useNewTrialState();
  const tabs = [];
  const [showDrawer, setShowDrawer] = useState(false);

  const hideDrawer = (): void => {
    setShowDrawer(false);
  };

  const openDrawer = (): void => {
    setShowDrawer(true);
  };

  if (trialProtocolData?.product_details?.seeds) {
    tabs.push({ key: "seeds", label: "Seeds" });
  }

  if (trialProtocolData?.product_details?.nutrients) {
    tabs.push({ key: "fertilisers", label: "Fertilizers" });
  }
  useEffect(() => {
    setIsFertilizerTabActive(
      trialProtocolData?.protocol_type?.[0] === "nutrient"
    );
    return () => {
      setIsFertilizerTabActive(false);
    };
  }, [trialProtocolData]);

  return (
    <div>
      <ProtcolDrawer
        open={showDrawer}
        onClose={hideDrawer}
        protocolId={trialProtocolData?.id ?? ""}
      />
      <Tabs
        defaultActiveKey="1"
        tabBarExtraContent={
          <Button
            type="text"
            onClick={openDrawer}
            icon={<InfoCircleOutlined />}
          >
            Protocol info
          </Button>
        }
        onChange={(key) => {
          setIsFertilizerTabActive(key === "fertilisers");
        }}
      >
        {tabs.map((tab) => (
          <TabPane tab={tab.label} key={tab.key}>
            <RatesAndDosagesGrid
              data={data}
              trialProtocolData={trialProtocolData}
              type={tab.key as "seeds" | "fertilisers"}
            />
          </TabPane>
        ))}
      </Tabs>
    </div>
  );
};

export default SummaryRatesAndDosage;
