import { useState, type ReactElement, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import * as amplitude from "@amplitude/analytics-browser";
import { PrimaryButton } from "../../shared/layout/PrimaryButton";
import { FilterOutlined, PlusOutlined } from "@ant-design/icons";
import "./style.less";
import { Search } from "../../shared/layout/Search";
import { Button } from "syngenta-digital-cropwise-react-ui-kit";
import usePagination from "../../shared/custom-hooks/usePagination";
import AxiosInstance from "../../shared/utils/axios";
import { useAppState } from "../../lib/appContext/AppContext";
import Table from "../../shared/layout/table";
import { type TrialDataInterface } from "./types";
import { TrialListColumns } from "./constant";
import { route } from "../constant";
import { useTranslation } from "react-i18next";
import { formatTranslation } from "../../shared/utils/translationUtils";
import TrailModal from "../../shared/layout/Modal";
import type { monitorModaldata } from "../../shared/layout/types";
import useDownloadWorkOrder from "../../shared/custom-hooks/useDownloadWorkOrder";
import { Loader } from "../../shared/components/Loader";
import ProtocolDrawer from "../../shared/layout/ProtocolDrawer";
import { useAuth } from "../../lib/auth/AuthContext";
import { getUserAmplitudeData } from "../../shared/utils/getUserAmplitudeData";

const CreateNewTrial = (): ReactElement => {
  const { t } = useTranslation();
  const [trialData, setTrialData] = useState<TrialDataInterface[]>([]);
  const { stateValue } = useAppState();
  const [searchText, setSearchText] = useState<string>("");
  const [filteredTrialData, setFilteredTrialData] = useState<
    TrialDataInterface[]
  >([]);
  const { pagination, handleTableChange } = usePagination(
    10,
    trialData?.length
  );
  const [loading, setLoading] = useState<boolean>(false);
  const { downloadISOXMLOrder, downloadmonitorModalForm, isLoading } =
    useDownloadWorkOrder();
  const [modalOpen, setModalOpen] = useState(false);
  const [fieldIds, setfieldIds] = useState("");
  const [trailId, setTrialId] = useState("");
  const [protocolId, setProtocolId] = useState("");
  const [open, setOpen] = useState(false);
  const { user } = useAuth();
  const amplitudeUserData = getUserAmplitudeData(user, stateValue);

  const handleOk = (data: monitorModaldata, fielFormdData: any): void => {
    if (
      data.selectedmonitorBrand === "isoxml" ||
      data.selectedmonitorModel === "isoxml"
    ) {
      downloadISOXMLOrder(fielFormdData, trailId).catch(() => {
        console.error("Error While Fetching API..!");
      });
    } else {
      downloadmonitorModalForm(data).catch(() => {
        console.error("Erroe While Fetching API..!");
      });
    }
    setModalOpen(false);
  };

  const handleCancel = (): void => {
    setModalOpen(false);
  };

  const openModal = (trailId: string, fieldIds: string): void => {
    amplitude.logEvent("Trial-Design", { buttonName: "Download Popup" }, amplitudeUserData);
    setModalOpen(true);
    setfieldIds(fieldIds);
    setTrialId(trailId);
  };

  const fetchData = async (): Promise<void> => {
    setLoading(true);
    try {
      const response = await AxiosInstance.get(
        `/${route.TRIALS.path}?org_id=${
          stateValue?.orgValue?.id as string
        }&property_id=${stateValue?.farmValue?.id as string}&limit=999`
      );
      setTrialData(response.data);
      setLoading(false);
      return response?.data;
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const handleDeleteTrialData = async (id: string): Promise<any> => {
    amplitude.logEvent("Trial-Design", { buttonName: "Delete Trial" }, amplitudeUserData);
    await AxiosInstance.delete(`/trial/${id}`);
    await fetchData();
  };

  const filterTrialData = (): void => {
    const filteredData = trialData?.filter((entry: any) => {
      return entry?.name?.includes(searchText);
    });
    setFilteredTrialData(filteredData);
  };

  const openDrawer = (protocolId: string): void => {
    if (protocolId) {
      setProtocolId(protocolId);
      setOpen(true);
    }
  };

  const onClose = (): void => {
    setOpen(false);
  };

  const trialColumns = TrialListColumns(
    handleDeleteTrialData,
    openModal,
    openDrawer
  );

  const navigate = useNavigate();
  const handleNavigate = (): void => {
    amplitude.logEvent("Trial-Design", { buttonName: "Create Trial" }, amplitudeUserData);
    navigate(`/app/${route.NEWTRIAL.path}`);
  };

  useEffect(() => {
    filterTrialData();
  }, [searchText, trialData]);

  useEffect(() => {
    const fetchDataAndHandleError = async (): Promise<void> => {
      try {
        await fetchData();
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchDataAndHandleError().catch((error) => {
      console.error("Error handling fetchDataAndHandleError:", error);
      return error;
    });
  }, [stateValue?.orgValue]);

  return (
    <div className="create-trail-main">
      {isLoading && <Loader mask message="" />}
      {fieldIds && trailId && (
        <TrailModal
          open={modalOpen}
          handleOk={handleOk}
          handleCancel={handleCancel}
          fieldId={fieldIds}
          trialId={trailId}
        />
      )}
      {protocolId && (
        <ProtocolDrawer protocolId={protocolId} open={open} onClose={onClose} />
      )}
      <div className="head-sec">
        <h3>{formatTranslation(t("trials.title"))}</h3>
        <PrimaryButton onClick={handleNavigate}>
          <PlusOutlined /> {formatTranslation(t("trials.createButton"))}
        </PrimaryButton>
      </div>
      <div className="body-content">
        <div className="search-sec">
          <h3>{formatTranslation(t("trials.description"))}</h3>
          <div className="search-right">
            <Search searchText={searchText} setSearchTexts={setSearchText} />
            {
              // TODO: display button while we have option for filter.
              /* <Button className="filter"> 
              <FilterOutlined />
            </Button> */
            }
          </div>
        </div>
        <div className="table-sec">
          <Table
            columns={trialColumns}
            dataSource={filteredTrialData || trialData}
            onChange={handleTableChange}
            pagination={pagination}
            loading={loading}
          />
        </div>
      </div>
    </div>
  );
};

export default CreateNewTrial;
