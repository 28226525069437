import React, { type FC, useState, useEffect, useMemo } from "react";
import {
  type TrialPlotsPopupProps,
  type RowsColumnsType,
  type Plots,
  type TrialPlot,
} from "../../../../../pages/new-trail/types";
import { Button } from "syngenta-digital-cropwise-react-ui-kit";
import { Flex, InputNumber, Popover } from "antd";
import { usePlotCardState } from "../../utils";
import { CloseOutlined } from "@ant-design/icons";
import RowColumn from "../../../RowColumn/index";
import { useNewTrialState } from "../../../../../pages/new-trail/hooks/NewTrialStateProvider";
import { useTranslation } from "react-i18next";
import { formatTranslation } from "../../../../utils/translationUtils";

const DEFAULT_PLOT_WIDTH = 50;
const DEFAULT_PLOT_HEIGHT = 50;
const MAX_WIDTH_HEIGHT = 999;

export const TrialPlotsPopup: FC<TrialPlotsPopupProps> = ({
  openPopoverKey,
  onHandleData,
  children,
  val = 0,
  indexKey,
}) => {
  const { t } = useTranslation();
  const { popoverState, setPopoverState } = usePlotCardState();
  const { newTrialState, trialProtocolData, setNewTrialState } =
    useNewTrialState();

  const [inputValue, setInputValue] = useState<RowsColumnsType>({
    rows: 0,
    columns: 0,
    plotWidth: trialProtocolData?.plot_recommended_size?.width,
    plotHeight: trialProtocolData?.plot_recommended_size?.length,
  });

  const isTrialPlotValid = useMemo(() => {
    const { plotWidth, plotHeight, rows, columns } = inputValue;
    const { plot_minimum_size: plotSize } = trialProtocolData ?? {};

    if (
      (plotWidth as number) > MAX_WIDTH_HEIGHT ||
      (plotHeight as number) > MAX_WIDTH_HEIGHT
    ) {
      return true;
    }

    if (
      (plotWidth as number) < (plotSize?.width ?? 0) ||
      (plotHeight as number) < (plotSize?.length ?? 0) ||
      rows === 0 ||
      columns === 0
    ) {
      return true;
    }

    return false;
  }, [inputValue]);

  useEffect(() => {
    setInputValue((oldValue) => {
      return {
        ...oldValue,
        plotWidth: trialProtocolData?.plot_recommended_size?.width,
        plotHeight: trialProtocolData?.plot_recommended_size?.length,
      };
    });
  }, [trialProtocolData]);

  const getData = (): Plots[] => {
    const getObjectData = newTrialState?.trial_plots?.map(
      (plotData: TrialPlot, i: number) => {
        if (indexKey === i) {
          return plotData.plot;
        }
        return plotData.plot;
      }
    );
    // console.log( getObjectData)
    return getObjectData;
  };

  const handleOpenChange = (key: string): void => {
    setPopoverState((prevState: Record<string, number | null>) => ({
      ...prevState,
      [key]: val,
    }));
    if (openPopoverKey === "openEditPopover") {
      setInputValue({
        rows: getData()[indexKey as number].rows,
        columns: getData()[indexKey as number].columns,
        plotWidth: getData()[indexKey as number].column_size,
        plotHeight: getData()[indexKey as number].row_size,
      });
      setNewTrialState((prevState: any) => {
        return {
          ...prevState,
          trial_plots: prevState.trial_plots.map(
            (plotData: TrialPlot, i: number) => {
              if (indexKey === i) {
                return {
                  ...plotData,
                  plot: {
                    ...plotData.plot,
                    isEditing: true,
                  },
                };
              }
              return plotData;
            }
          ),
        };
      });
    }
  };

  const handleApplydata = (): void => {
    if (inputValue.rows !== 0 && inputValue.columns !== 0) {
      if (openPopoverKey === "openPopover") {
        onHandleData(
          inputValue.rows,
          inputValue.columns,
          inputValue.plotWidth,
          inputValue.plotHeight
        );
      } else {
        onHandleData(
          inputValue.rows,
          inputValue.columns,
          inputValue.plotWidth,
          inputValue.plotHeight,
          openPopoverKey,
          val,
          indexKey
        );
      }
      setPopoverState((prevState: Record<string, number | null>) => ({
        ...prevState,
        openPopover: null,
        openEditPopover: null,
      }));
      setInputValue({
        rows: 0,
        columns: 0,
        plotHeight:
          trialProtocolData?.plot_recommended_size?.length ??
          DEFAULT_PLOT_HEIGHT,
        plotWidth:
          trialProtocolData?.plot_recommended_size?.width ?? DEFAULT_PLOT_WIDTH,
      });
      setDisableSelection(false);
      // handle for recommended size
    }
  };

  const [disableSelection, setDisableSelection] = useState<boolean>(false);

  const generateBoxes = (): JSX.Element[] => {
    const totalBoxes = 32;
    const boxElements = [];
    const selectedRows = inputValue.rows;
    const selectedColumns = inputValue.columns;

    const handleBoxHover = (row: number, column: number): void => {
      setInputValue((oldValue) => {
        return { ...oldValue, rows: row + 1, columns: column + 1 };
      });
    };

    const handleBoxClick = (rows: number, columns: number): void => {
      setInputValue((oldValue) => {
        return { ...oldValue, rows: rows + 1, columns: columns + 1 };
      });
      setDisableSelection(!disableSelection);
      // handleApplydata();
    };

    const isBoxClicked = (row: number, column: number): boolean => {
      return [inputValue].some(
        (box) => box.rows === row && box.columns === column
      );
    };

    for (let i = 0; i < totalBoxes; i++) {
      const row = Math.floor(i / 8);
      const column = i % 8;
      const isActive =
        row < selectedRows && column < selectedColumns ? " active" : "";
      const isClicked = isBoxClicked(row, column) ? " clicked" : "";
      boxElements.push(
        <Button
          key={i}
          className={"boxes" + isActive + isClicked}
          onMouseEnter={() => {
            if (!disableSelection) handleBoxHover(row, column);
          }}
          onClick={() => {
            handleBoxClick(row, column);
          }}
        ></Button>
      );
    }

    return boxElements;
  };

  const handleClosePopover = (): void => {
    setInputValue({
      rows: 0,
      columns: 0,
      plotHeight:
        trialProtocolData?.plot_recommended_size?.length ?? DEFAULT_PLOT_HEIGHT,
      plotWidth:
        trialProtocolData?.plot_recommended_size?.width ?? DEFAULT_PLOT_WIDTH,
    });
    setDisableSelection(false);
    setPopoverState((prevState: Record<string, number | null>) => ({
      ...prevState,
      openPopover: null,
      openEditPopover: null,
    }));
  };

  const titleContent = (
    <div className="popover-title">
      <span>{formatTranslation(t("newTrial.plotDesign.trialPlots"))}</span>
      <Button className="close-btn" onClick={handleClosePopover}>
        <CloseOutlined />
      </Button>
    </div>
  );

  const content = (
    <div className="buffer-zone-popup">
      <p className="desc">
        {formatTranslation(t("trials.plotDesign.trialPlots.popUp"))}
      </p>
      <div className="box-plot">{generateBoxes()}</div>
      <div className="zone-input-sec">
        <RowColumn inputValue={inputValue} setInputValue={setInputValue} />
        <p className="">
          Plot Size{" "}
          <span className="text-sm">
            (Min {trialProtocolData?.plot_minimum_size?.width}x
            {trialProtocolData?.plot_minimum_size?.length}m
          </span>
          <span className="text-sm">| Max 999x999m)</span>
        </p>
        <Flex vertical={false} gap={32}>
          <div>
            <label htmlFor="Rows">Width (m)</label>
            <div className="input-main">
              <InputNumber
                type="number"
                data-testid="row-input"
                value={inputValue.plotWidth}
                // min={trialProtocolData?.plot_minimum_size?.width}
                onChange={(value) => {
                  value && setInputValue({ ...inputValue, plotWidth: value });
                }}
                controls={false}
              />
            </div>
          </div>
          <div>
            <label htmlFor="Columns">Height (m)</label>
            <div className="input-main">
              <InputNumber
                type="number"
                data-testid="column-input"
                value={inputValue.plotHeight}
                // min={trialProtocolData?.plot_minimum_size?.height}
                onChange={(value) => {
                  value && setInputValue({ ...inputValue, plotHeight: value });
                }}
                controls={false}
              />
            </div>
          </div>
        </Flex>
      </div>
      <Button
        className={`apply-btn apply-trial-btn ${
          isTrialPlotValid ? "disable" : ""
        }`}
        disabled={isTrialPlotValid}
        onClick={handleApplydata}
      >
        {formatTranslation(t("trials.plotDesign.trialPlots.buttonText"))}
      </Button>
    </div>
  );

  return (
    <>
      {popoverState[openPopoverKey] === val && (
        <Button
          onClick={handleClosePopover}
          className="popover-backdrop"
        ></Button>
      )}
      <Popover
        content={content}
        title={titleContent}
        placement="right"
        trigger={"click"}
        open={popoverState[openPopoverKey] === val}
        onOpenChange={() => {
          handleOpenChange(openPopoverKey);
        }}
      >
        {children}
      </Popover>
    </>
  );
};
