import { type ReactElement } from "react";
import { Tabs } from "antd";
import { protocolProductTabs } from "../constants";
import { getDefaultActiveTabs } from "../utils";
import { useCreateProtocolForm } from "..";

const ProductsComponent = (): ReactElement => {
  const { formValidationData } = useCreateProtocolForm();

  const onChange = (key: string): void => {};

  return (
    <div className="products-component">
      <Tabs
        defaultActiveKey={
          formValidationData?.protocolType &&
          getDefaultActiveTabs(formValidationData?.protocolType)
        }
        items={protocolProductTabs(formValidationData?.protocolType)}
        onChange={onChange}
      />
    </div>
  );
};

export default ProductsComponent;
